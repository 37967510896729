import "flatpickr/dist/themes/material_blue.css";
import React from "react";
import { useLocation } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import LoaderComponent from "../../components/LoaderComponent";
import StackedColumnChart from "../../components/common/StackedColumnChart";
import TableContainer from "../../components/common/TableContainer";
import { AppContext } from "../../context/AppProvider";
import { Layout } from "../../layout";
import { Breadcrumbs } from "../../layout/Breadcrumbs";
import { PortCentralServer } from "../../lib/domain/Ports/PortCentralServer";
import { SubscriptionPurchaseStatus } from "../../lib/drivers/dto/enums/SubscriptionPurchaseStatus";
import { UserSubscriptionStageIndexDayDto } from "../../lib/drivers/dto/subscriptions/UserSubscriptionStageIndexDayDto";
import { ViewUserSubscriptionProgressInfoDto } from "../../lib/drivers/dto/subscriptions/ViewUserSubscriptionProgressInfoDto";
import withRouterHook from "../../withRouterHook";

export type DashboardPageProps = {
  location?: ReturnType<typeof useLocation>;
};

type SeriesItem = {
  name: string;
  data: number[];
};

type ReportItem = {
  title: string;
  iconClass: string;
  description: string;
};

type DashboardPageState = {
  isLoading: boolean;
  userSubscriptionsView: ViewUserSubscriptionProgressInfoDto[];
  userSubscriptionStageIndexDaysData: UserSubscriptionStageIndexDayDto[];
  reportDetails: ReportItem[];
  tableData: Array<{
    ctimeStamp: string;
    contractName: string;
    leverageLevel: number;
    volume: string;
    pnl: string;
  }>;
};

class DashboardPage extends React.Component<DashboardPageProps, DashboardPageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: DashboardPageState = {
    isLoading: true,
    userSubscriptionsView: [],
    userSubscriptionStageIndexDaysData: [],
    reportDetails: [],
    tableData: []
  };

  async componentDidMount() {
    try {
      const reportDetails: ReportItem[] = [];

      const userSubscriptions = (await PortCentralServer.Repo.client.filterViewUserSubscriptionProgressInfos({})).data;
      const totalUsers = await PortCentralServer.Repo.client.getUserCount();

      reportDetails.push({ title: "Total Users", iconClass: "bx-user", description: totalUsers.toLocaleString() });

      if (userSubscriptions) {
        let totalOrders = 0;
        let totalVolume = 0;

        for (const userSubscription of userSubscriptions) {
          const { userSubscriptionUserId, userSubscriptionId, currentStageIndexId, fundTradingProgramTitle, currentStageIndexTotalOrders, currentStageIndexTradingVolume } =
            userSubscription;

          totalOrders += currentStageIndexTotalOrders;
          totalVolume += Number(currentStageIndexTradingVolume);

          if (userSubscriptionId && currentStageIndexId) {
            const userSubscriptionStagesData = await PortCentralServer.Repo.client.getUserSubscriptionStagesData(userSubscriptionId);
            if (userSubscriptionStagesData) {
              for (const userSubscriptionStage of userSubscriptionStagesData) {
                await this.fetchData(userSubscriptionUserId, userSubscriptionId, userSubscriptionStage.userSubscriptionStageIndexId, fundTradingProgramTitle);
              }
            }
          }
        }

        const billingsCount = (await PortCentralServer.Repo.client.filterUserSubscriptionPurchases({})).data.filter(
          (billing) => billing.status === SubscriptionPurchaseStatus.COMPLETED
        ).length;

        const payouts = await PortCentralServer.Repo.client.filterUserSubscriptionPayouts({});
        
        reportDetails.push({ title: "Total Orders", iconClass: "bx-copy-alt", description: totalOrders.toLocaleString() });
        reportDetails.push({ title: "Total Volume", iconClass: "bx-copy-alt", description: `${Number(totalVolume).toFixed(6).toLocaleString()} USDT` });
        reportDetails.push({ title: "Total Purchases", iconClass: "bx-copy-alt", description: billingsCount.toLocaleString() });
        reportDetails.push({ title: "Total Payouts", iconClass: "bx-copy-alt", description: payouts.total.toLocaleString() });

        this.setState({ reportDetails });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  fetchData = async (userId: string, userSubscriptionId: string, userSubscriptionStageIndexId: string, fundTradingProgramTitle: string) => {
    try {
      const [resUserSubscriptionStageIndexDaysData, userSubscriptionStageIndexDayOrdersData] = await Promise.all([
        PortCentralServer.Repo.client.getUserSubscriptionStageIndexDays(userSubscriptionId, userSubscriptionStageIndexId),
        PortCentralServer.Repo.client.getUserSubscriptionStageIndexDayOrders(userSubscriptionId, userSubscriptionStageIndexId)
      ]);

      // get only stage index days records with orders
      const userSubscriptionStageIndexDaysData = resUserSubscriptionStageIndexDaysData.filter((ele) => ele.totalOrders > 0);

      if (userSubscriptionStageIndexDaysData && userSubscriptionStageIndexDayOrdersData.total > 0) {
        const updatedUserSubscriptionStageIndexDaysData = userSubscriptionStageIndexDaysData.map((item) => ({
          ...item,
          fundTradingProgramTitle
        }));

        const tableData = userSubscriptionStageIndexDayOrdersData.data.map((item) => ({
          ctimeStamp: new Date(item.ctimeStamp).toLocaleString("en-US", { hour12: false }),
          contractName: item.contractName,
          leverageLevel: item.leverageLevel,
          volume: item.volume.toLocaleString("en-US", { maximumFractionDigits: 2 }),
          pnl: Number(item.pnl).toFixed(6).toString()
        }));

        this.setState((prevState) => ({
          userSubscriptionStageIndexDaysData: [...prevState.userSubscriptionStageIndexDaysData, ...updatedUserSubscriptionStageIndexDaysData],
          tableData: [...prevState.tableData, ...tableData]
        }));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  ChartContent = () => {
    const { userSubscriptionStageIndexDaysData } = this.state;

    if (userSubscriptionStageIndexDaysData.length === 0) return null;

    const dateToSubscriptionMap = new Map<string, Map<string, number>>();
    const idToTitleMap = new Map<string, string>();

    userSubscriptionStageIndexDaysData.sort((a, b) => {
      const dateA = new Date(a.year, a.month - 1, a.day);
      const dateB = new Date(b.year, b.month - 1, b.day);
      return dateA.getTime() - dateB.getTime();
    });

    userSubscriptionStageIndexDaysData.forEach((item) => {
      const dateKey = `${item.year}-${item.month}-${item.day}`;
      if (!dateToSubscriptionMap.has(dateKey)) {
        dateToSubscriptionMap.set(dateKey, new Map<string, number>());
      }
      const subscriptionMap = dateToSubscriptionMap.get(dateKey)!;
      subscriptionMap.set(item.userSubscriptionStageIndexId, Number(Number(item.totalRealizedPnl).toFixed(6)));
      idToTitleMap.set(item.userSubscriptionStageIndexId, item.fundTradingProgramTitle);
    });

    const categories = Array.from(dateToSubscriptionMap.keys());
    const uniqueSubscriptions = Array.from(new Set(userSubscriptionStageIndexDaysData.map((item) => item.userSubscriptionStageIndexId)));

    const data: SeriesItem[] = uniqueSubscriptions.map((subscriptionId) => {
      const pnlValuesForSeries = categories.map((dateKey) => dateToSubscriptionMap.get(dateKey)?.get(subscriptionId) ?? null);
      return {
        name: idToTitleMap.get(subscriptionId) || `Subscription ${subscriptionId}`,
        data: pnlValuesForSeries
      };
    });

    return (
      <CardBody>
        <h5 className="card-title me-2">Total PNL (USDT)</h5>
        <hr className="mb-4" />
        <div id="area-chart" dir="ltr">
          <StackedColumnChart categories={categories} periodData={data} dataColors='["--bs-primary", "--bs-warning", "--bs-success"]' />
        </div>
      </CardBody>
    );
  };

  render() {
    const columns = [
      { Header: "Timestamp", accessor: "ctimeStamp" },
      { Header: "Contract Name", accessor: "contractName" },
      { Header: "Leverage Level", accessor: "leverageLevel" },
      { Header: "Volume (USDT)", accessor: "volume" },
      { Header: "PNL (USDT)", accessor: "pnl" }
    ];

    return (
      <Layout>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Account" breadcrumbItem="Progress" />
            <Row>
              <Col xl="12">
                {this.state.isLoading ? (
                  <LoaderComponent />
                ) : (
                  <>
                    <Row className="justify-content-center">
                      {this.state.reportDetails.map((detail, key) => (
                        <Col key={key}>
                          <Card className="mini-stats-wid">
                            <CardBody>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <p className="fw-medium">{detail.title}</p>
                                  <h4 className="mb-0">{detail.description}</h4>
                                </div>
                                {/* <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                  <span className="avatar-title rounded-circle bg-primary">
                                    <i className={`bx ${detail.iconClass} font-size-24`} />
                                  </span>
                                </div> */}
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                    <Row>
                      <Card>
                        <this.ChartContent />
                      </Card>
                    </Row>
                    <Row>
                      <CardBody>
                        <div className="mb-4 h4 card-title">Latest Progress</div>
                        <TableContainer columns={columns} data={this.state.tableData} isGlobalFilter={false} isAddOptions={false} customPageSize={20} />
                      </CardBody>
                    </Row>
                  </>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouterHook(DashboardPage);
