import classnames from "classnames";
import "flatpickr/dist/themes/material_blue.css";
import moment from "moment";
import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Card, CardBody, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import LoaderComponent from "../../../components/LoaderComponent";
import { AppContext } from "../../../context/AppProvider";
import { Layout } from "../../../layout";
import { Breadcrumbs } from "../../../layout/Breadcrumbs";
import { PortCentralServer } from "../../../lib/domain/Ports/PortCentralServer";
import { StageIndexStatus } from "../../../lib/drivers/dto/enums/StageIndexStatus";
import { SubscriptionStatus } from "../../../lib/drivers/dto/enums/SubscriptionStatus";
import { UserSubscriptionPayoutDto } from "../../../lib/drivers/dto/subscriptions/UserSubscriptionPayoutDto";
import { UserSubscriptionStageIndexDto } from "../../../lib/drivers/dto/subscriptions/UserSubscriptionStageIndexDto";
import { ViewUserSubscriptionProgressInfoDto } from "../../../lib/drivers/dto/subscriptions/ViewUserSubscriptionProgressInfoDto";
import withRouterHook from "../../../withRouterHook";
import { ModalUserSubscriptionActivate, ModalUserSubscriptionActivateArgs } from "./ModalUserSubscriptionActivate";
import { ModalUserSubscriptionDeactivate, ModalUserSubscriptionDeactivateArgs } from "./ModalUserSubscriptionDeactivate";
import { ModalUserSubscriptionIndexActivate, ModalUserSubscriptionIndexActivateArgs } from "./ModalUserSubscriptionIndexActivate";
import { UserSubscriptionItemComponent } from "./UserSubscriptionItemComponent";
import { UserSubscriptionPayoutListComponent } from "./UserSubscriptionPayoutListComponent";
import { UserSubscriptionStageIndexListComponent } from "./UserSubscriptionStageIndexListComponent";

type SubscriptionDetailsPageParams = ReturnType<typeof useParams> & {
  userSubscriptionId: string;
};

export type SubscriptionEditPageProps = {
  location?: ReturnType<typeof useLocation>;
  params?: SubscriptionDetailsPageParams;
};

type SubscriptionEditPageState = {
  // page
  activeTab: string;

  // loading
  isLoadingUserSubscriptionItem: boolean;
  isLoadingUserSubscriptionStageIndexList: boolean;
  isLoadingUserSubscriptionPayoutList: boolean;

  // data
  dataUserSubscriptionItem: ViewUserSubscriptionProgressInfoDto;
  dataUserSubscriptionStageIndexList: UserSubscriptionStageIndexDto[];
  dataUserSubscriptionPayoutList: UserSubscriptionPayoutDto[];

  // actions
  openModalUserSubscriptionDeactivate: boolean;
  openModalUserSubscriptionActivate: boolean;
  openModalUserSubscriptionIndexActivate: boolean;
};

class SubscriptionEditPage extends React.Component<SubscriptionEditPageProps, SubscriptionEditPageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: SubscriptionEditPageState = {
    // page
    activeTab: "1",

    // loading
    isLoadingUserSubscriptionItem: true,
    isLoadingUserSubscriptionStageIndexList: true,
    isLoadingUserSubscriptionPayoutList: true,

    // data
    dataUserSubscriptionItem: null,
    dataUserSubscriptionStageIndexList: null,
    dataUserSubscriptionPayoutList: null,

    // actions
    openModalUserSubscriptionDeactivate: false,
    openModalUserSubscriptionActivate: false,
    openModalUserSubscriptionIndexActivate: false
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection

    await this.loadUserSubscriptionItem();
    await this.loadUserSubscriptionStageIndexList();
    await this.loadingLoadingUserSubscriptionPayoutList();
  };

  componentDidUpdate = async (prevProps: SubscriptionEditPageProps, prevState: SubscriptionEditPageState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  toggleTab = async (tab: string) => {
    if (this.state.activeTab !== tab) {
      await this.setState({
        activeTab: tab
      });
    }
  };
  // #endregion

  // #region State Setters
  onPayoutComplete = async () => {
    await this.loadUserSubscriptionItem();
    await this.loadUserSubscriptionStageIndexList();
    await this.loadingLoadingUserSubscriptionPayoutList();
  };
  // #endregion

  // #region Functions
  loadUserSubscriptionItem = async () => {
    await this.setState((prevState) => {
      return {
        isLoadingUserSubscriptionItem: true
      };
    });

    // request
    const response = await PortCentralServer.Repo.client.getViewUserSubscriptionProgressInfoById(this.props.params.userSubscriptionId);

    if (response) {
      await this.setState((prevState) => {
        return {
          dataUserSubscriptionItem: response,
          isLoadingUserSubscriptionItem: false
        };
      });
    } else {
      await this.setState((prevState) => {
        return {
          isLoadingUserSubscriptionItem: false
        };
      });
    }
  };

  loadUserSubscriptionStageIndexList = async () => {
    await this.setState((prevState) => {
      return {
        isLoadingUserSubscriptionStageIndexList: true
      };
    });

    // request
    const response = await PortCentralServer.Repo.client.getUserSubscriptionStagesData(this.props.params.userSubscriptionId);
    response.sort((a, b) => a.stageSequence - b.stageSequence);

    if (response) {
      await this.setState((prevState) => {
        return {
          dataUserSubscriptionStageIndexList: response,
          isLoadingUserSubscriptionStageIndexList: false
        };
      });
    } else {
      await this.setState((prevState) => {
        return {
          isLoadingUserSubscriptionStageIndexList: false
        };
      });
    }
  };

  loadingLoadingUserSubscriptionPayoutList = async () => {
    await this.setState((prevState) => {
      return {
        isLoadingUserSubscriptionPayoutList: true
      };
    });

    // request
    const response = await PortCentralServer.Repo.client.filterUserSubscriptionPayouts({ userSubscriptionId: this.props.params.userSubscriptionId });

    if (response && response.data) {
      await this.setState((prevState) => {
        return {
          dataUserSubscriptionPayoutList: response.data,
          isLoadingUserSubscriptionPayoutList: false
        };
      });
    } else {
      await this.setState((prevState) => {
        return {
          isLoadingUserSubscriptionPayoutList: false
        };
      });
    }
  };

  getNextFundTradingProgramStageId = () => {
    if (this.state.dataUserSubscriptionItem) {
      const nextStage = this.state.dataUserSubscriptionItem.fundTradingProgramStages.find(
        (stage) => stage.sequence === this.state.dataUserSubscriptionItem.fundTradingProgramStageSequence
      );

      const nextFundTradingProgramStageId = nextStage ? nextStage.fundTradingProgramStageId : null;
      return nextFundTradingProgramStageId;
    }

    return null;
  };
  // #endregion

  // #region Action Activate
  onActionUserSubscriptionActivateClick = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();

    await this.setState((prevState) => {
      return {
        openModalUserSubscriptionActivate: true
      };
    });
  };

  onModalUserSubscriptionActivateCloseClick = async () => {
    await this.setState((prevState) => {
      return { openModalUserSubscriptionActivate: false };
    });
  };

  onModalUserSubscriptionActivateSubmitClick = async (args: ModalUserSubscriptionActivateArgs) => {
    const ok = await PortCentralServer.Repo.client.activateUserSubscription({
      userSubscriptionId: this.props.params.userSubscriptionId,
      credentials: {
        platformKind: args.platformKind,
        uid: args.uid,
        username: args.username,
        password: args.password,
        mfa: args.mfa
      }
    });

    if (ok) {
      await this.loadUserSubscriptionItem();
      await this.loadUserSubscriptionStageIndexList();
    }

    await this.setState((prevState) => {
      return { openModalUserSubscriptionActivate: false };
    });
  };
  // #endregion

  // #region Action Index Activate
  onActionUserSubscriptionIndexActivateClick = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();

    await this.setState((prevState) => {
      return {
        openModalUserSubscriptionIndexActivate: true
      };
    });
  };

  onModalUserSubscriptionIndexActivateCloseClick = async () => {
    await this.setState((prevState) => {
      return { openModalUserSubscriptionIndexActivate: false };
    });
  };

  onModalUserSubscriptionIndexActivateSubmitClick = async (args: ModalUserSubscriptionIndexActivateArgs) => {
    const nextFundTradingProgramStageId = this.getNextFundTradingProgramStageId();

    const tomorrowMidnight = moment().add(1, "days").startOf("day");

    const ok = await PortCentralServer.Repo.client.activateUserSubscriptionIndex({
      userSubscriptionId: this.props.params.userSubscriptionId,
      fundTradingProgramStageId: nextFundTradingProgramStageId,
      dateStart: tomorrowMidnight.toDate(),
      credentials: {
        platformKind: args.platformKind,
        uid: args.uid,
        username: args.username,
        password: args.password,
        mfa: args.mfa
      }
    });

    if (ok) {
      await this.loadUserSubscriptionItem();
      await this.loadUserSubscriptionStageIndexList();
    }

    await this.setState((prevState) => {
      return { openModalUserSubscriptionIndexActivate: false };
    });
  };
  // #endregion

  // #region Action Deactivate
  onActionUserSubscriptionDeactivateClick = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();

    await this.setState((prevState) => {
      return {
        openModalUserSubscriptionDeactivate: true
      };
    });
  };

  onModalUserSubscriptionDeactivateCloseClick = async () => {
    await this.setState((prevState) => {
      return { openModalUserSubscriptionDeactivate: false };
    });
  };

  onModalUserSubscriptionDeactivateSubmitClick = async (args: ModalUserSubscriptionDeactivateArgs) => {
    const ok = await PortCentralServer.Repo.client.deactivateUserSubscription({
      userSubscriptionId: this.props.params.userSubscriptionId,
      message: args.message
    });

    if (ok) {
      await this.loadUserSubscriptionItem();
      await this.loadUserSubscriptionStageIndexList();
    }

    await this.setState((prevState) => {
      return { openModalUserSubscriptionDeactivate: false };
    });
  };
  // #endregion

  // #region Renders
  // #endregion

  render() {
    const nextFundTradingProgramStageId = this.getNextFundTradingProgramStageId();

    return (
      <React.Fragment>
        <Layout>
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="Subscriptions" breadcrumbItem="Details" />
              <Row>
                <Col xl={12}>
                  {this.state.isLoadingUserSubscriptionItem ? (
                    <LoaderComponent />
                  ) : (
                    <>
                      <Card>
                        <CardBody>
                          <Row>
                            <Col xl={12}>
                              <UserSubscriptionItemComponent entity={this.state.dataUserSubscriptionItem} />
                            </Col>
                          </Row>
                          <hr />
                          <Row className="mt-4">
                            <Col>
                              {this.state.dataUserSubscriptionItem.userSubscriptionStatus === SubscriptionStatus.PAID && (
                                <button onClick={(e) => this.onActionUserSubscriptionActivateClick(e)} className="btn-rounded btn btn-warning me-2">
                                  Activate
                                </button>
                              )}

                              {this.state.dataUserSubscriptionItem.userSubscriptionStatus !== SubscriptionStatus.FAILED && (
                                <button onClick={(e) => this.onActionUserSubscriptionDeactivateClick(e)} className="btn-rounded btn btn-warning me-2">
                                  Deactivate
                                </button>
                              )}

                              {this.state.dataUserSubscriptionItem.currentStageIndexStatus == StageIndexStatus.PROCESSED && nextFundTradingProgramStageId && (
                                <button onClick={(e) => this.onActionUserSubscriptionIndexActivateClick(e)} className="btn-rounded btn btn-primary me-2">
                                  Activate Stage
                                </button>
                              )}

                              <Link to={`/subscriptions/${this.state.dataUserSubscriptionItem.userSubscriptionId}/progress`} className="btn-rounded btn btn-info me-2">
                                Progress
                              </Link>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </>
                  )}
                </Col>
              </Row>

              <div className="card-body">
                <Nav tabs>
                  <NavItem>
                    <NavLink className={classnames({ active: this.state.activeTab === "1" })} onClick={() => this.toggleTab("1")}>
                      Stages
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={classnames({ active: this.state.activeTab === "2" })} onClick={() => this.toggleTab("2")}>
                      Payouts
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <Row>
                      <Col xl={12}>
                        {this.state.isLoadingUserSubscriptionStageIndexList ? (
                          <LoaderComponent />
                        ) : (
                          <>
                            <Card>
                              <CardBody>
                                <Row>
                                  <Col xl={12}>
                                    <UserSubscriptionStageIndexListComponent list={this.state.dataUserSubscriptionStageIndexList} />
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </>
                        )}
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col xl={12}>
                        {this.state.isLoadingUserSubscriptionPayoutList ? (
                          <LoaderComponent />
                        ) : (
                          <>
                            <Card>
                              <CardBody>
                                <Row>
                                  <Col xl={12}>
                                    <UserSubscriptionPayoutListComponent list={this.state.dataUserSubscriptionPayoutList} onPayoutComplete={this.onPayoutComplete} />
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </>
                        )}
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        </Layout>

        {this.state.openModalUserSubscriptionDeactivate && (
          <ModalUserSubscriptionDeactivate
            userSubscriptionId={this.props.params.userSubscriptionId}
            onCloseClick={this.onModalUserSubscriptionDeactivateCloseClick}
            onSubmitClick={this.onModalUserSubscriptionDeactivateSubmitClick}
          />
        )}

        {this.state.openModalUserSubscriptionActivate && (
          <ModalUserSubscriptionActivate
            userSubscriptionId={this.props.params.userSubscriptionId}
            onCloseClick={this.onModalUserSubscriptionActivateCloseClick}
            onSubmitClick={this.onModalUserSubscriptionActivateSubmitClick}
          />
        )}

        {this.state.openModalUserSubscriptionIndexActivate && (
          <ModalUserSubscriptionIndexActivate
            userSubscriptionId={this.props.params.userSubscriptionId}
            onCloseClick={this.onModalUserSubscriptionIndexActivateCloseClick}
            onSubmitClick={this.onModalUserSubscriptionIndexActivateSubmitClick}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withRouterHook(SubscriptionEditPage);
